const degrees = [
  {
    school: 'Purdue University',
    degree: 'B.S. in Electrical Engineering',
    link: 'purdue.edu',
    year: 2023,
  },
];

export default degrees;
