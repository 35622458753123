// TODO: Add Athletic Skills, Office Skills,
// Data Engineering, Data Science, ML Engineering, ... ?

const skills = [  
  {title: 'Embedded C',    
    competency: 5,    
    category: ['Software'],  
  },
  {
    title: 'Assembly',
    competency: 4,
    category: ['Software',],
  },
  {
    title: 'Python',
    competency: 4,
    category: ['Software'],
  },
  {
    title: 'Distributed Control Systems',
    competency: 5,
    category: ['Manufacturing'],
  },
  {
    title: 'Rockwell Automation',
    competency: 5,
    category: ['Manufacturing'],
  },
  {
    title: 'SAP',
    competency: 5,
    category: ['Manufacturing'],
  },
  {
    title: 'SystemVerilog',
    competency: 3,
    category: ['Hardware'],
  },
  {
    title: 'KiCad',
    competency: 4,
    category: ['Computer Aided Design'],
  },
  {
    title: 'Fusion360',
    competency: 4,
    category: ['Computer Aided Design'],
  },
  {
    title: 'PLCs',
    competency: 5,
    category: ['Manufacturing'],
  },
  {
    title: 'P&IDs/E&I Drawings',
    competency: 5,
    category: ['Manufacturing'],
  },
  {
    title: 'I/O Systems',
    competency: 5,
    category: ['Manufacturing'],
  },
  {
    title: '3D/CAD',
    competency: 4,
    category: ['Computer Aided Design'],
  },
  {
    title: 'PCB design',
    competency: 4,
    category: ['Computer Aided Design'],
  },
  {
    title: 'Microprocessor/FPGA Design',
    competency: 4,
    category: ['Hardware'],
  },
  {
    title: 'Heat Tracing',
    competency: 5,
    category: ['Manufacturing'],
  },
].map((skill) => ({ ...skill, category: skill.category.sort() }));

// this is a list of colors that I like. The length should be == to the
// number of categories. Re-arrange this list until you find a pattern you like.
const colors = [
  
  '#7a7a7a',
  '#4d4d4d',
  '#2b2b2b',
  '#000000',
  '#e47272',
  '#cc7b94',
  '#3896e2',
  '#c3423f',
  '#d75858',
  '#747fff',
  '#64cb7b',
];

const categories = [
  ...new Set(skills.reduce((acc, { category }) => acc.concat(category), [])),
]
  .sort()
  .map((category, index) => ({
    name: category,
    color: colors[index],
  }));

export { categories, skills };
