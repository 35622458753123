const courses = [
  {
    title: 'Advanced Analog Electronic Circuits, Singals and Systems Labaratory',
    number: 'ECE 306',
  },
  {
    title: 'Advanced C Programming',
    number: 'ECE 264',
  },
  {
    title: 'Digital Systems and FPGA Design',
    number: 'ECE 270',
  },

  {
    title: 'Nanotechnology, Quantum Transport and Boltzmann Law',
    number: 'ECE 506',
  },
  {
    title: 'Micro-Electromechanical Systems and Nanoelectronics',
    number: 'ECE 526',
  },
  {
    title: 'Introduction to Semiconductor Devices',
    number: 'ECE 305',
  },
  {
    title: 'Electronic Analysis and Design',
    number: 'ECE 202',
  },
  {
    title: 'Electronic Devices and Design Labaratory',
    number: 'ECE 208',
  },
  {
    title: 'Electronic Measurement Techniques',
    number: 'ECE 207',
  },
  {
    title: 'Linear Circuit Analysis',
    number: 'ECE 201',
  },
  {
    title: 'Microprocessor Systems and Interfacing',
    number: 'ECE 362',
  },
  {
    title: 'Probabilistic Methods in Electrical and Computer Engineering',
    number: 'ECE 302',
  },
  {
    title: 'Python for Data Science',
    number: 'ECE 275',
  },
  {
    title: 'Signals and Systems',
    number: 'ECE 301',
  },

];

export default courses;
