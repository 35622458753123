const positions = [
  {
    company: 'Bayer',
    position: 'Electrical Project Engineer Intern',
    link: 'https://bayer.com',
    daterange: 'May 2023 - August 2023',
    points: [
      'Assist senior engineers with electrical engineering and project management of capital projects from project initiation through completion.',
      'Create project cost estimates, identify cost-saving opportunities, and provide management with background and justification for project funding.',
      'Participate in project reviews, follow up on action items, and contribute to the development of appropriation requests.',
      'Provide engineering assistance to site personnel to ensure compliance with plant and corporate standards, safety procedures, and regulatory requirements for the design and implementation of capital projects.',
    ],
  },
  {
    company: 'Bayer',
    position: 'Electrical Engineer Co-Op',
    link: 'https://bayer.com',
    daterange: 'June 2022 - December 2022',
    points: [
      'Demonstrated expertise in distributed control systems, instrumentation, and I/O systems, ensuring compliance with electrical norms and industry advancements.',
      'Conducted cost-benefit analyses to identify, design, and implement automation and control system improvements while prioritizing safety, reliability, quality, and compatibility with existing infrastructure.',
      'Performed electrical maintenance tasks, including troubleshooting transformers, switchgears, and motor control centers, to enhance reliability, safety, and performance of electrical systems in accordance with OSHA and NFPA standards.',
      'Collaborated with area engineers to implement focused improvement and maintenance projects in formulations and packaging areas.',

    ],
  },
  {
    company: 'Purude University',
    position: 'Laboratory Teaching Assistant',
    link: 'purdue.edu',
    daterange: 'January 2021 - May 2023',
    points: [
      'Evaluate course assignments and develop feedback in assistance to the instructional team.',
      'Create student performance feedbacks to optimize learning experience.',
      'Mentor learners with building, editing, and submission of objects as relative to course assignments.',
      'Debug circuit designs and conduct linear circuit analysis on learner projects.',
    ],
  },
];

export default positions;
